<template>
  <v-dialog
    v-if="campaignLandingPageConfig != null"
    v-model="show"
    max-width="480px"
    scrollable
  >
    <template>
      <SimplifiedCheckout
        :campaignLandingPageConfig="campaignLandingPageConfig"
        :landingPageOptions="landingPageOptions"
        :projectId="projectId"
      ></SimplifiedCheckout>
    </template>
  </v-dialog>
</template>

<script>
import SimplifiedCheckout from "../simplifiedCheckout/SimplifiedCheckout";
import CommonHelper from "@/scripts/helpers/common.helper";
import CampaignService from "@/scripts/services/campaign.service";
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";

export default {
  components: { SimplifiedCheckout },
  props: ["show", "projectId"],
  data() {
    return {
      campaignLandingPageConfig: null,
      landingPageOptions: new CampaignLandingPageOptions(),
      campaignService: new CampaignService(),
    };
  },
  watch: {
    show: function (newValue, old) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
  created() {
    if (CommonHelper.getSubdomain()) {
      this.campaignService
        .getCampaignLandingPageConfigBySubdomain()
        .then((data) => {
          this.campaignLandingPageConfig = data;
          if (
            this.campaignLandingPageConfig &&
            this.campaignLandingPageConfig.campaign &&
            this.campaignLandingPageConfig.campaign.file
          ) {
            this.landingPageOptions.logo =
              this.campaignLandingPageConfig.campaign.file;
          }
        });
    }
  },
};
</script>
<style>
.v-dialog > .widget {
  margin-bottom: 0;
}
</style>