<template>
  <div class="box-list">
    <div class="box-list-header d-flex align-center justify-content-space-between">
      <div class="box-list-header-title">{{ attributeOffer.offerName }}</div>

      <div class="d-flex flex-shrink-0">
          <v-tooltip top>
          <template v-slot:activator="{on}">
            <div v-on="on">                      
              <Icon name="fal fa-globe-americas icon-attribute" v-if="attributeOffer.isDistanceOportunityAllowed"/>
            </div>
          </template>
          <span>Atuação à distância</span>
        </v-tooltip>    
        
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <div v-on="on">                      
              <Icon name="fal fa-users  icon-attribute" v-if="attributeOffer.isPresentialOportunityAllowed"/>
            </div>
          </template>
          <span>Atuação presencial</span>
        </v-tooltip>  
        
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <div v-on="on">                      
              <Icon name="fal fa-calendar icon-attribute" v-if="attributeOffer.volunteeringType.name == 'Pontual'"/>
            </div>
          </template>
          <span>Vaga Pontual</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{on}">
            <div v-on="on">                      
              <Icon name="fal fa-sync icon-attribute" v-if="attributeOffer.volunteeringType.name == 'Recorrente'"/>
            </div>
          </template>
          <span>Vaga Recorrente</span>
        </v-tooltip>
      </div>
      
      
    </div>
    <div class="image" v-if="attributeOffer.file">
      <img :src="attributeOffer.file.path" alt />
    </div>
    <div class="box-list-body">
      <div class="mb-3" v-if="attributeOffer.function">
        <h6>função</h6>
        <p>{{ attributeOffer.function }}</p>
      </div>
      <div class="mb-3" v-if="attributeOffer.prerequisites">
        <h6>pré-requisitos</h6>
        <p v-html="attributeOffer.preRequisites"></p>
      </div>
      <template
        v-if="attributeOffer.volunteeringType.name == 'Recorrente'"
        v-for="recurrentType in attributeOffer.volunteeringRecurrentTypes"
      >
        <div class="hours" :key="recurrentType.id">
          <div class="icon mr-3">
            <Icon name="fal fa-sync" size="24" />
          </div>
          <div>
            <h5>{{ getRecurrentWeekHoursText(recurrentType) }}</h5>
            <h3>{{ getRecurrentWeekDayText(recurrentType) }}</h3>
            <p>{{ getRecurrentWeekTimeText(recurrentType) }}</p>
          </div>
        </div>
        <div class="subs-box mt-3">
          <b>Voluntáriado até: {{ getRecurrentLimitDateText(recurrentType) }}</b>
        </div>
      </template>
      <div
        class="hours align-center mb-2"
        v-if="attributeOffer.volunteeringType.name == 'Pontual'"
        v-for="pontualType in attributeOffer.volunteeringPontualTypes"
        :key="pontualType.id"
      >
        <Icon name="fas fa-calendar" class="text-primary mr-4" size="20" />
        <h5 class="flex-grow-1">{{ getPontualTypeDateText(pontualType) }}</h5>
        <p class="ml-4">{{ getPontualTypeTimeText(pontualType) }}</p>
      </div>
    </div>
    <!-- Multiple subscription option set in true -->
    <div class="px-3 pt-3 pb-4" v-if="multipleSlot">
      <v-btn
        round
        block
        color="btn-primary text-white"
        light
        class="ml-0"
        @click="$emit('selectOffer', attributeOffer)"
        v-if="VolunteerSlot.indexOf(attributeOffer.id) === -1 && (attributeOffer.totalOfferAmount - totalRegistrationAmount) > 0"
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />
        Inscreva-se
      </v-btn>
      <v-btn
        round
        block
        color="btn-secondary"
        light
        class="ml-0"
        @click="$emit('selectOffer', attributeOffer)"
        v-else-if="VolunteerSlot.indexOf(attributeOffer.id) !== -1 && (!attributeOffer.isDeleted)"
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />
        Inscrito
      </v-btn>
      <v-btn
        round
        block
        color="btn-primary text-white"
        light
        class="ml-0"
        @click="$emit('selectOffer', attributeOffer, true)"
        v-else-if="VolunteerSlot.indexOf(attributeOffer.id) !== -1 && (attributeOffer.isDeleted)"
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />
        Inscreva-se
      </v-btn>
      <v-btn
        round
        block
        color="btn-secondary"
        light
        class="ml-0"
        v-else-if="(attributeOffer.totalOfferAmount - totalRegistrationAmount) === 0"
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />Vagas esgotadas
      </v-btn>
    </div>
      <!-- Multiple subscription option set to false -->
    <div class="px-3 pt-3 pb-4" v-if="!multipleSlot">
      <v-btn
        round
        block
        color="btn-primary text-white"
        light
        class="ml-0"
        @click="$emit('selectOffer', attributeOffer)"
        v-if="!hasSubscribedOnVolunteerSlot && ((attributeOffer.totalOfferAmount - totalRegistrationAmount) > 0)"
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />Quero me
        Inscrever
      </v-btn>
      <v-btn
        round
        block
        color="btn-secondary"
        light
        class="ml-0"
        @click="$emit('selectOffer', attributeOffer)"
        v-else-if="hasSubscribedOnVolunteerSlot && (VolunteerSlot.indexOf(attributeOffer.id) !== -1) && (!attributeOffer.isDeleted)"
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />
        Inscrito
      </v-btn>
      <v-btn
        round
        block
        color="btn-secondary"
        light
        class="ml-0"
        v-else-if="hasSubscribedOnVolunteerSlot && (VolunteerSlot.indexOf(attributeOffer.id) === -1) && (attributeOffer.totalOfferAmount - totalRegistrationAmount) !== 0"
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />Você já está incrito em uma vaga
      </v-btn>
      <v-btn
        round
        block
        color="btn-secondary"
        light
        class="ml-0"
        disabled
        v-else
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />Vagas esgotadas
      </v-btn>
    </div>

    <div class="subs-box radius-0">
      <v-layout align-center justify-space-between flex-wrap>
        <strong>{{ totalRegistrationAmount }}/{{ attributeOffer.totalOfferAmount }}</strong>
      </v-layout>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service";
import LoginService from "@/scripts/services/login.service";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    attributeOffer: Object,
    volunteeringAttributeOfferId: Object,
    isSubscribed: Boolean,
    VolunteerSlot: Array,
    hasSubscribedOnVolunteerSlot: Boolean,
    multipleSlot: Boolean,
    institution: Object,
  },
  data() {
    return {
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      loginService: new LoginService(),
      totalRegistrationAmount: 0,
      isLogged: false
    };
  },
  computed: {
    ...mapGetters(["logged"]),
  },
  watch: {
    attributeOffer(newValue) {
      this.volunteeringRegistrationOfferService.findTotalRegistrationAmountByVolunteeringAttributeOfferId(newValue.id).then(data => {
        this.totalRegistrationAmount = data
      })
    },
    volunteerSlot(newValue){
      this.volunteerSlot = newValue
    },
    logged(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.isLogged = newValue
      }
    },
    institution(val) {
      this.attributeOffer.institution = val
    }
  },
  async mounted() {
    await this.loginService.verifyToken(data => {
      this.setLogged(data)
    });
    this.volunteeringRegistrationOfferService.findTotalRegistrationAmountByVolunteeringAttributeOfferId(this.attributeOffer.id).then(data => {
      this.totalRegistrationAmount = data
    });
  },
  methods: {
    ...mapActions(["setLogged"]),
    showLogin() {
      this.$store.commit("SHOW_LOGIN");
    },
    getRecurrentWeekDayText(recurrentType) {
      var weekDays = [];
      recurrentType.volunteeringRecurrentWeekDays.forEach(weekDay => {
        if (weekDay.weekDayName == "Segunda") {
          weekDays.push({ order: 1, name: 'Seg' });
        }
        else if (weekDay.weekDayName == "Terça") {
          weekDays.push({ order: 2, name: 'Ter' });
        }
        else if (weekDay.weekDayName == "Quarta") {
          weekDays.push({ order: 3, name: 'Quar' });
        }
        else if (weekDay.weekDayName == "Quinta") {
          weekDays.push({ order: 4, name: 'Quinta' });
        }
        else if (weekDay.weekDayName == "Sexta") {
          weekDays.push({ order: 5, name: 'Sex' });
        }
        else if (weekDay.weekDayName == "Sábado") {
          weekDays.push({ order: 6, name: 'Sab' });
        }
        else if (weekDay.weekDayName == "Domingo") {
          weekDays.push({ order: 7, name: 'Dom' });
        }
      });

      if (weekDays.length == 0)
        return "Não definido";

      weekDays.sort((prev, next) => prev.order - next.order);

      if (this.weekDayHasGaps(weekDays))
        return this.getRecurrentWeekDayTextWithGaps(weekDays);

      return this.getRecurrentWeekDayTextWithoutGaps(weekDays);
    },
    weekDayHasGaps(weekDays) {
      let hasGap = false;
      let index = 0;
      let weekDayBefore = null;
      while (!hasGap && index < weekDays.length) {

        let weekDay = weekDays[index];
        if (weekDayBefore != null && weekDay.order - 1 != weekDayBefore.order)
          hasGap = true;

        weekDayBefore = weekDays[index];
        index++;
      }

      return hasGap;
    },
    getRecurrentWeekDayTextWithGaps(weekDays) {
      let result = ""
      for (let index = 0; index < weekDays.length; index++) {
        const weekDay = weekDays[index];
        result += weekDay.name;

        if (index + 2 == weekDays.length) {
          result += " e "
        }
        else if (index + 1 < weekDays.length) {
          result += ", "
        }
      }
      return result;
    },
    getRecurrentWeekDayTextWithoutGaps(weekDays) {
      if (weekDays.length == 0)
        return 'Não definido';

      if (weekDays.length > 1)
        return `${weekDays[0].name} à ${weekDays[weekDays.length - 1].name}`;

      return `${weekDays[0].name}`;
    },
    getRecurrentWeekHoursText(recurrentType) {
      if (recurrentType.minHoursDedication
        && recurrentType.maxHoursDedication
        && recurrentType.minHoursDedication != recurrentType.maxHoursDedication)
        return `De ${recurrentType.minHoursDedication} a ${recurrentType.maxHoursDedication} Horas Semanais`;

      if (recurrentType.minHoursDedication)
        return `${recurrentType.minHoursDedication} Horas Semanais`;

      if (recurrentType.maxHoursDedication)
        return `${recurrentType.maxHoursDedication} Horas Semanais`;

      return "Não definido";
    },
    getRecurrentWeekTimeText(recurrentType) {
      if (recurrentType.initialTime
        && recurrentType.finalTime
        && recurrentType.initialTime != recurrentType.finalTime)
        return `${this.getTime(recurrentType.initialTime)}h às ${this.getTime(recurrentType.finalTime)}h`;
      if (recurrentType.initialTime)
        return `às ${this.getTime(recurrentType.initialTime)}h`;
      if (recurrentType.finalTime)
        return `às ${this.getTime(recurrentType.finalTime)}h`;
    },    
    getRecurrentLimitDateText(recurrentType) {
      return moment(recurrentType.volunteeringDateUntil).format('DD/MM/YYYY');
    },
    getPontualTypeDateText(pontualType) {
      return moment(pontualType.volunteeringDate).format('DD/MM/YYYY');
    },
    getPontualTypeTimeText(pontualType) {
      if (pontualType.initialTime
        && pontualType.finalTime
        && pontualType.initialTime != pontualType.finalTime)
        return `${this.getTime(pontualType.initialTime)}h às ${this.getTime(pontualType.finalTime)}h`;
      if (pontualType.initialTime)
        return `às ${this.getTime(pontualType.initialTime)}h`;
      if (pontualType.finalTime)
        return `às ${this.getTime(pontualType.finalTime)}h`;
    },
    getTime(date) {
      const timeSplit = date.split(':')
      const time = new Date(null, null, null, timeSplit[0], timeSplit[1])
      return (time.getHours()).toString().padStart(2,'0') + ':' 
      + (time.getMinutes()).toString().padStart(2,'0')
    }
  }
};
</script>

<style>
</style>